<template>
  <v-container>
    <div>
      <transition name="fade">
        <Loading v-if="isLoading"></Loading>
      </transition>
    </div>
    <v-row dense class="my-0 flex-row align-center justify-space-around">
      <v-col>
        <h3 class="pl-2 text-center headline">
          Cuisine Filter
        </h3>
        <CuisineFilter class="mb-1"/>
      </v-col>
      <v-col>
        <h3 class="pl-2 text-center headline">
          Category Filter
        </h3>
        <CategoryFilter class="mb-1"/>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field
            outlined
            clearable
            color="black"
            append-icon=mdi-magnify
            label="Ingredients (e.g. potato,tomato,fish)"
            v-model.trim="ingredients"
            :rules="[rules.ingredient]"
            @keydown.enter="submitSearchRequest()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2" sm="12">
        <v-text-field class="mt-0 pt-0" color="black" type="number" v-model="number" outlined
                      label="max # of results"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-spacer></v-spacer>
      <v-btn
          class="mx-2"
          color="#FFE0B2"
          text-color="black"
          @click="azSort(recipes)">
        <v-icon left>mdi-sort</v-icon>
        sort
      </v-btn>
      <v-btn
          class="mx-2"
          color="#FFE0B2"
          text-color="black"
          @click="shuffleSort(recipes)">
        <v-icon left>mdi-shuffle</v-icon>
        shuffle
      </v-btn>
    </v-row>
    <v-alert
        v-if="emptyResult"
        type="error"
        transition="scale-transition"
    >
      No results!
    </v-alert>
    <v-row v-if="isShow">
      <v-col :sm="6" :md="6" :lg="4" :xl="3" v-for="recipe in recipes.slice(0, number)" :key="recipe.id">
        <v-lazy transition="fade">
          <RecipeCard
              :title="recipe.title"
              :id="recipe.id"
              :image="recipe.image"
              @click="pushID(recipe.id)"
          />
        </v-lazy>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CuisineFilter from "@/components/Search/CuisineFilter";
import CategoryFilter from "@/components/Search/CategoryFilter";
import RecipeCard from "@/components/Recipe/RecipeCard";
import Loading from '@/views/Loading';
import {sortAToZ, shuffle} from "@/utils/sort";

export default {
  name: "Search",
  data() {
    return {
      recipes: [],
      safeSearch: false,
      ingredients: '',
      number: 10,
      isLoading: false,
      isActive: false,
      isShow: false,
      emptyResult: false,
      rules: {
        ingredient: value => {
          const pattern = /^[A-Za-z\s]+(,[A-Za-z\s]+)*$/
          if (pattern.test(value) && !(typeof value == "undefined" || value == null || value === "")) {
            this.safeSearch = true;
            return true
          } else {
            this.safeSearch = false
            return 'Invalid, please check the input:)'
          }
        },
      }
    }
  },
  methods: {
    pushID(id) {
      this.$router.push({
            path: '/recipe/' + id
          }
      )
    },
    submitSearchRequest() {
      this.recipes = []
      //this.isLoading = true
      const intolerance = this.$store.getters.getIntolerance
      const diet = this.$store.getters.getDiet
      const cuisine = this.$store.state.select_cuisine
      const type = this.$store.state.select_category
      const ingredients = this.ingredients
      const number = this.number

      let tag = ''
      let intoleranceTag = null
      let dietTag = null
      let cuisineTag = null
      let typeTag = null

      if (intolerance != null) {
        if (intolerance.length != 0) {
          intoleranceTag = "&intolerances=" + intolerance
        }
      }

      if (diet != null) {
        if (diet.length != 0) {
          dietTag = "&diet=" + diet
        }
      }

      if (cuisine != null) {
        if (cuisine.length != 0) {
          cuisineTag = "&cuisine=" + cuisine
        }
      }

      if (type != null) {
        if (type.length != 0) {
          typeTag = "&type=" + type
        }
      }

      const tags = [intoleranceTag, dietTag, cuisineTag, typeTag]
      for (const item of tags) {
        if (item != null) {
          tag = tag + item
        }
      }

      if (tag != null) {
        tag = tag.toLowerCase()
        if (this.safeSearch) {
          this.$axios.get("https://api.spoonacular.com/recipes/complexSearch" +
              "?instructionsRequired=true" +
              "&apiKey=641c5346a1cb4594ba2c9eb7c1889ca5" +
              "&includeIngredients=" + ingredients
              + tag
              + "&number=" + number).then(res => {
            console.log('recipe search request sent')
            console.log(res.data)
            if ((res.data.results.toString()).length == 0) {
              this.emptyResult = true
            }
            this.recipes = res.data.results;
            this.$store.commit('apiStatusMut', false)
          })
              .catch((err) => {
                this.$store.commit('apiStatusMut', true)
                console.log(err)
              })
        }
      }
      this.isLoading = false
    },
    /*changeData() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        console.log(this.recipes)
      }, 500)
      if (this.safeSearch) {
        this.recipes = [
          {
            id: 656971,
            title: "Potato Leek Soup",
            image: "https://spoonacular.com/recipeImages/656971-312x231.jpg",
            imageType: "jpg"
          },
          {
            id: 657011,
            title: "Potato-Cheese Pie",
            image: "https://spoonacular.com/recipeImages/657011-312x231.jpg",
            imageType: "jpg"
          },
          {
            id: 636729,
            title: "Potato Chip Brownies",
            image: "https://spoonacular.com/recipeImages/636729-312x231.jpg",
            imageType: "jpg"
          },
          {
            id: 656921,
            title: "Potato and Leek Gratin",
            image: "https://spoonacular.com/recipeImages/656921-312x231.jpg",
            imageType: "jpg"
          },
          {
            id: 656962,
            title: "Potato Gratin with Goat Cheese",
            image: "https://spoonacular.com/recipeImages/656962-312x231.jpg",
            imageType: "jpg"
          },
          {
            id: 656925,
            title: "Potato and Scallion Bread Rolls",
            image: "https://spoonacular.com/recipeImages/656925-312x231.jpg",
            imageType: "jpg"
          },
          {
            id: 656915,
            title: "Potato and Green Bean Side Salad",
            image: "https://spoonacular.com/recipeImages/656915-312x231.jpg",
            imageType: "jpg"
          },
          {
            id: 657000,
            title: "Potato Soup With Peppers and Olives",
            image: "https://spoonacular.com/recipeImages/657000-312x231.jpg",
            imageType: "jpg"
          },
          {
            id: 1096323,
            title: "Potato & Leek Soup with Roasted Beets",
            image: "https://spoonacular.com/recipeImages/1096323-312x231.jpg",
            imageType: "jpg"
          },
          {
            id: 1697549,
            title: "Potato and Leek Soup – Potage Parmentier",
            image: "https://spoonacular.com/recipeImages/1697549-312x231.jpg",
            imageType: "jpg"
          }
        ]
      }
    },*/
    shuffleSort(data) {
      shuffle(data)
    },
    azSort(data) {
      sortAToZ(data)
    }
  }
  ,
  created() {
    this.$store.commit('changeCurrRecipeIdMut', '')
    this.$store.commit('changeCurrRecipeTitleMut', '')
  }
  ,
  components: {
    CuisineFilter,
    CategoryFilter,
    RecipeCard,
    Loading
  }
  ,
  watch: {
    recipes() {
      if (this.isShow) {
        this.isShow = false
        setTimeout(() => (this.isShow = true), 100)
      } else {
        this.isShow = true
      }
    }
    ,
    emptyResult() {
      if (this.emptyResult == true) {
        setTimeout(() => (this.emptyResult = false), 2000)
      }
    }
  }
}

</script>
