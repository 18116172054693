<template>
  <div>
    <v-autocomplete
        :items="items"
        :value="selected"
        chips
        persistent-hint
        item-text="name"
        label="Intolerance"
        multiple
        clearable
        color="black"
        v-model="selected"
        :disabled="edit"
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
            v-bind="attrs"
            filled
            :input-value="selected"
            close
            @click="select"
            color="#FFE0B2"
            text-color="balck"
            @click:close="remove(item)"
        >
          <strong>{{ item }}</strong>
        </v-chip>
      </template>
    </v-autocomplete>
  </div>

</template>

<script>
export default {
  name: "Intolerance",
  props: {
    edit: null,
  },
  data() {
    return {
      selected: [],
      items: this.$store.state.intolerances,
    };
  },
  created() {
    if(this.$store.state.select_intolerances.intolerances != null){
      if(this.$store.state.select_intolerances.intolerances.length > 0 ){
        this.selected = this.$store.state.select_intolerances.intolerances.split(',')
      }
    }

  },
  watch: {
    selected() {
      this.$store.commit('changeIntolerancesMut', this.selected.toString())
    },
    edit() {

    }
  },
  methods: {
    remove(item) {
      this.selected.splice(this.selected.indexOf(item), 1)
      this.selected = [...this.selected]
    },

  }
}

</script>

<style scoped>

</style>