<template>
  <v-autocomplete
      :items="items"
      :value="selected"
      chips
      item-text="name"
      persistent-hint
      multiple
      clearable
      v-model="selected"
      color="black"
  >
    <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
          v-bind="attrs"
          filled
          :input-value="selected"
          close
          @click="select"
          color="#FFE0B2"
          text-color="balck"
          @click:close="remove(item)"
      >
        <strong>{{ item }}</strong>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "CuisineFilter",
  data() {
    return {
      selected: [],
      items: this.$store.state.cuisine
    };
  },
  created() {
    this.$store.commit('changeCuisineMut',[])
  },
  watch:{
    selected(newSelected){
      this.$store.commit('changeCuisineMut',newSelected)
    },
  },
  methods:{
    remove(item) {
      this.selected.splice(this.selected.indexOf(item), 1)
      this.selected = [...this.selected]
    },
  }
}
</script>

<style scoped>

</style>