<template>
  <v-app style="background: #FFF8E1">
    <AppBar/>
    <v-main>
      <v-alert transition="scale-transition" v-if="this.$store.state.api_error" type="error">
        API request error, try again later :)
      </v-alert>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@/components/UI/AppBar";

export default {
  name: 'app',
  components: {
    AppBar
  },
  watch: {
    '$route.path'() {
      this.$store.commit('apiStatusMut', false)
    }
  },

}
</script>
