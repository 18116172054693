<template>
  <v-container>
    <div>
      <transition name="fade">
        <Loading v-if="isLoading"></Loading>
      </transition>
    </div>
    <v-card transition="scale-transition" class="d-print-none" color="transparent" elevation="20">
      <a :href="recipe.image">
        <v-img
            height="300"
            min-height="125"
            :src="recipe.image"
            class="d-print-none"
        >
        </v-img>
      </a>
      <RecipeInformation v-if="!isLoading" :recipe="recipe"/>
    </v-card>
  </v-container>
</template>

<script>
import RecipeInformation from "@/components/Recipe/RecipeInformation";
import Loading from "./Loading";
export default {
  components: {
    RecipeInformation,
    Loading
  },

  data() {
    return {
      isLoading: true,
      recipe:Object,
    };
  },

  created() {
    this.updateRecipe()
  },

  mounted() {
  },

  watch: {
    '$route.params.id'(){
      this.updateRecipe()
    }
  },

  computed: {},
  methods: {
    updateRecipe() {
      this.isLoading=true
      this.$axios.get("https://api.spoonacular.com/recipes/" +
          this.$route.params.id +
          "/information?includeNutrition=true" +
          "&apiKey=641c5346a1cb4594ba2c9eb7c1889ca5").then(res => {
        console.log('information request sent')
        this.$store.commit('apiStatusMut',false)
        this.recipe = res.data;
        document.title = res.data.title + " - Cookie"
        this.isLoading = false;
      })
          .catch((err) => {
            this.$store.commit('apiStatusMut',true)
            console.log(err)
            this.isLoading = false;
          })
    }
  },

};
</script>

<style>
</style>
