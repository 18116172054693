<template>
  <v-container>
    <v-row class="my-0 flex-row align-center justify-space-around">
      <v-col>
        <v-card transition="fade" color="transparent" elevation="20">
          <v-card-title class="text-h5 font-weight-regular brown lighten-1 white--text">
            Settings
          </v-card-title>
          <v-card-text>
            <v-subheader class="pa-0">
              Do you have any intolerances?
            </v-subheader>
            <Intolerance class="mb-1" :edit="!isEditable"/>
            <v-divider></v-divider>
            <v-subheader class="pa-0">
              Are you on any diet?
            </v-subheader>
            <Diet class="mb-1" :edit="!isEditable"/>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="ma-2" v-if="$vuetify.breakpoint.smAndUp">
            <v-btn
                color="primary"
                label
                rounded
                @click="askDialog = true"
            >
              <v-icon left>
                mdi-cloud
              </v-icon>
              Cloud Sync
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
                color="red"
                outlined
                @click="clear_data"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              Clear local data
            </v-btn>

            <v-btn
                color="green"
                outlined
                @click="isEditable=!isEditable"
                v-if="isEditable"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              Click to Save
            </v-btn>
            <v-btn
                color="green"
                outlined
                @click="isEditable=!isEditable"
                v-else
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              Click to Edit
            </v-btn>
          </v-card-actions>

          <v-card-actions class="ma-2" v-if="$vuetify.breakpoint.xsOnly">
            <v-row align="center">
              <v-col align-self="center" cols="12">
                <v-btn
                    color="primary"
                    label
                    rounded
                    @click="askDialog = true"
                >
                  <v-icon left>
                    mdi-cloud
                  </v-icon>
                  Cloud Sync
                </v-btn>
              </v-col>
              <v-col align-self="center" cols="12">
                <v-btn
                    color="red"
                    outlined
                    @click="clear_data"
                >
                  <v-icon left>
                    mdi-delete
                  </v-icon>
                  Clear local data
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                    color="green"
                    outlined
                    @click="isEditable=!isEditable"
                    v-if="isEditable"
                >
                  <v-icon left>
                    mdi-check
                  </v-icon>
                  Click to Save
                </v-btn>
                <v-btn
                    color="green"
                    outlined
                    @click="isEditable=!isEditable"
                    v-else
                >
                  <v-icon left>
                    mdi-pencil
                  </v-icon>
                  Click to Edit
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
    <v-dialog
        v-model="askDialog"
        persistent
        max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Cloud Sync
        </v-card-title>
        <v-card-text> You can back up your <b>favourite recipe(s), intolerance(s) and diet preference</b> to the cloud.
          <br>
          You can restore your data from anywhere by providing the sync code. <br>
          Do you want to proceed?
        </v-card-text>
        <v-card-text>
          Powered by jsonstorage.net
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              outlined
              @click="askDialog = false"
          >
            No, please don't
          </v-btn>
          <v-btn
              color="green darken-1"
              outlined
              @click="askDialog=false
                      syncDialog=true"
          >
            Yes, please!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="syncDialog"
        persistent
        max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Cloud Sync
        </v-card-title>
        <v-card-text>Please enter your sync code or generate a new one by clicking 'upload' below.
        </v-card-text>
        <v-card-text>
          <v-row class="mx-0">
            <v-text-field
                color="black"
                dense
                outlined
                label="Sync code"
                v-model="uuid"
            >
            </v-text-field>
            <v-btn
                icon
                @click="copyText(uuid)
                        copyClicked=!copyClicked">
              <v-icon color="blue darken-1" v-if="!copyClicked">mdi-content-copy</v-icon>
              <v-icon color="green darken-1" v-else>mdi-check</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-text v-if="syncAction">
          <v-alert v-if="syncStatus=true" transition="fade" type="success">
            Completed! SAVE YOUR CODE :)
          </v-alert>
          <v-alert v-if="syncStatus=false" transition="fade" type="error">
            Failed!
          </v-alert>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-btn
              color="green darken-1"
              outlined
              @click="CloudSync"
          >
            <v-icon left>
              mdi-cloud-upload
            </v-icon>
            Upload
          </v-btn>
          <v-btn
              color="green darken-1"
              outlined
              @click="getJSON"
          >
            <v-icon left>
              mdi-cloud-download
            </v-icon>
            Recover
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="red darken-1"
              icon
              @click="reloadPage"
          >
            <v-icon>
              mdi-exit-to-app
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Intolerance from "@/components/Setting/Intolerance";
import Diet from "@/components/Setting/Diet";
import sync from "@/utils/sync"


export default {
  name: "Settings",
  components: {Intolerance, Diet},
  data() {
    return {
      askDialog: false,
      syncDialog: false,
      isEditable: false,
      syncStatus: false,
      syncAction: false,
      copyClicked: false,
      uuid: null,
      response: Object
    }
  },
  created() {
    this.uuid = this.$store.state.uuid
  },
  methods: {
    copyText(text) {
      navigator.clipboard.writeText(text);
    },
    clear_data() {
      localStorage.clear();
      location.reload()
    },
    reloadPage() {
      location.reload()
    },
    CloudSync() {
      if (!this.uuid) {
        this.createJSON()
      } else {
        this.updateJSON()
      }
    },
    createJSON() {
      this.syncAction = true
      sync.post('https://api.jsonstorage.net/v1/json/', Object.assign(this.$store.state.select_intolerances,
          this.$store.state.select_diet,
          this.$store.state.favourites)).then((response) => {
            this.uuid = response.uri.slice(36,)
            this.$store.commit('changeUUID', this.uuid)
            this.syncStatus = true
            console.log(response)
          }
      )
      setTimeout(() => (this.syncAction = false), 2000)
    },
    updateJSON() {
      this.syncAction = true
      sync.put('https://api.jsonstorage.net/v1/json/' + this.uuid, Object.assign(this.$store.state.select_intolerances,
          this.$store.state.select_diet,
          this.$store.state.favourites)).then((response) => {
            this.syncStatus = true
            console.log(response)
          }
      )
      setTimeout(() => (this.syncAction = false), 2000)
    },
    getJSON() {
      this.syncAction = true
      sync.get('https://api.jsonstorage.net/v1/json/' + this.uuid).then((response) => {
            this.$store.commit('recoverData', response)
            this.syncStatus = true
            console.log(response)
          }
      )
      setTimeout(() => (this.syncAction = false), 2000)
    },

  }
}
</script>
