<template>
  <div>
    <v-app-bar
        app
        color="brown"
        elevation="4"
    >
      <v-app-bar-nav-icon style="color: white" @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>

      <v-btn icon @click="$router.push('/')">
        <v-icon color="white">mdi-cookie</v-icon>
      </v-btn>

      <v-btn text class="pl-2" v-if="!isMobile" @click="$router.push('/')">
        <v-app-bar-title style="color: white">Cookie</v-app-bar-title>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
          class="ma-2"
          color="white"
          plain
          @click="randomRecipe()"
      >
        <v-icon left dark>
          mdi-slot-machine-outline
        </v-icon>
        Random Recipe
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        app
        left
        width="200px"
        style="background: #FFF8E1"
    >
      <v-list>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
            link
            @click="drawer = false"
        >
          <v-list-item-icon>
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>
  </div>
</template>

<script>
import Loading from "../../views/Loading";

export default {
  name: "AppBar",
  components: {
    Loading
  },
  data() {
    return {
      drawer: false,
      randomID: null,
      isLoading: false,
      items: [
        {title: 'Home', link: '/', icon: 'home'},
        {title: 'Search', link: '/search', icon: 'magnify'},
        {title: 'Favourites', link: '/favour', icon: 'heart'},
        {title: 'Settings', link: '/settings', icon: 'wrench'}
      ]
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    }
  },
  methods: {
    randomRecipe() {
      this.isLoading = true
      const intolerance = this.$store.getters.getIntolerance
      const diet = this.$store.getters.getDiet
      let tag = null
      if (intolerance != null && diet != null) {
        if (intolerance != '' && diet != '') {
          tag = intolerance + ',' + diet
        } else if (diet != '') {
          tag = diet
        } else {
          tag = intolerance
        }
        tag = tag.toLowerCase()
      }

      if (tag != null) {
        this.$axios.get("https://api.spoonacular.com/" +
            "recipes/random?number=1" +
            "&tags=" + tag +
            "&apiKey=641c5346a1cb4594ba2c9eb7c1889ca5").then(res => {
          console.log('random recipe request sent')
          console.log(res.data)
          setTimeout(() => (this.$router.push('/recipe/' + res.data.recipes[0].id), 1000))
          this.isLoading = false
        })
            .catch((err) => {
              this.$store.commit('apiStatusMut', true)
              this.isLoading = false
              console.log(err)
            })
      } else {
        this.$axios.get("https://api.spoonacular.com/" +
            "recipes/random?number=1" +
            "&apiKey=641c5346a1cb4594ba2c9eb7c1889ca5").then(res => {
          console.log('random recipe request sent')
          console.log(res.data)
          setTimeout(() => (this.$router.push('/recipe/' + res.data.recipes[0].id), 1000))
          this.isLoading = false
        })
            .catch((err) => {
              this.$store.commit('apiStatusMut', true)
              this.isLoading = false
              console.log(err)
            })
      }
    },
  },
}

</script>

<style>
a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}
</style>
