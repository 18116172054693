<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-title dense class="headline">Favourites</v-card-title>
      </v-col>
    </v-row>

    <v-row>
      <v-col :sm="6" :md="6" :lg="4" :xl="3" v-for="recipe in this.$store.state.favourites.favourites"
             :key="recipe.id">
          <RecipeCard
              :title="recipe.title"
              :id="recipe.id"
              :image="recipe.image"
              @click="pushID(recipe.id)"
          />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RecipeCard from "@/components/Recipe/RecipeCard";

export default {
  name: "Favourites",
  components: {
    RecipeCard
  },
  methods: {
    pushID(id) {
      this.$router.push({
            path: '/recipe/' + id
          }
      )
    },
  }
}
</script>
