import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        paths: ["uuid", "select_diet", "select_intolerances", "favourites"],
    }),], state: {
        category: ['main course', 'side dish', 'appetizer', 'breakfast', 'dessert', 'soup', 'drink', 'salad', 'bread', 'beverage', 'sauce', 'marinade', 'fingerfood', 'snack'],
        cuisine: ['African', 'American', 'British', 'Cajun', 'Caribbean', 'Chinese', 'Eastern European', 'European', 'French', 'German', 'Greek', 'Indian', 'Irish', 'Italian', 'Japanese', 'Jewish', 'Korean', 'Latin American', 'Mediterranean', 'Mexican', 'Middle Eastern', 'Nordic', 'Southern', 'Spanish', 'Thai', 'Vietnamese'],
        intolerances: ['Dairy', 'Egg', 'Gluten', 'Grain', 'Peanut', 'Seafood', 'Sesame', 'Shellfish', 'Soy', 'Sulfite', 'Tree Nut', 'Wheat'],
        diet: ['Vegan', 'Vegetarian', 'Lacto-Vegetarian', 'Ovo-Vegetarian', 'Ketogenic', 'Pescetarian', 'Paleo', 'Primal', 'Low FODMAP', 'Whole30'],
        current_recipe_id: null,
        current_recipe_title: 'Recipe',

        select_category: [],
        select_cuisine: [],

        uuid: '',
        select_intolerances: {"intolerances": []},
        select_diet: {"diet": ''},
        favourites: {"favourites": []},

        api_error: false,
        isUpdating: false,
        autoUpdate: false
    }, mutations: {
        recoverData(state, params) {
            for (const key in params[Object.keys(params)[2]]) {
                if (params[Object.keys(params)[2]][key] == null) {
                    params[Object.keys(params)[2]].splice(key, 1);
                    console.log(params)
                }
            }
            state.favourites = {"favourites": params[Object.keys(params)[2]]}
            state.select_intolerances = {"intolerances": params[Object.keys(params)[0]]}
            state.select_diet = {"diet": params[Object.keys(params)[1]]}
        },
        apiStatusMut(state, params) {
            state.api_error = params
        }, changeCategoryMut(state, params) {
            state.select_category = params
        }, changeCuisineMut(state, params) {
            state.select_cuisine = params
        }, changeDietMut(state, params) {
            state.select_diet = {"diet": params}
        }, changeIntolerancesMut(state, params) {
            state.select_intolerances = {"intolerances": params}
        }, changeCurrRecipeIdMut(state, params) {
            state.current_recipe_id = params
        }, changeCurrRecipeTitleMut(state, params) {
            state.current_recipe_title = params
        }, changeUUID(state, params) {
            state.uuid = params
        }, addFav(state, params) {
            state.favourites.favourites.push(params)
        }, delFav(state, params) {
            for (const key in state.favourites.favourites) {
                if (state.favourites.favourites[key].id === params || state.favourites.favourites[key] == null) {
                    state.favourites.favourites.splice(key, 1);
                }
            }
        },

    }, getters: {
        getDiet: (state) => {
            if (state.select_diet.diet != null) {
                if (state.select_diet.diet.length != 0) {
                    return state.select_diet.diet
                }
            }
            return ''
        },
        getIntolerance: (state) => {
            if (state.select_intolerances.intolerances != null) {
                if (state.select_intolerances.intolerances.length !== 0) {
                    return state.select_intolerances.intolerances
                }
            }

            return ''
        },
    },

    actions: {}, modules: {}
})