<template>
  <div>
    <v-autocomplete
        :items="items"
        :value="selected"
        persistent-hint
        chips
        clearable
        label = 'Diet'
        item-text="name"
        v-model="selected"
        color="black"
        :disabled="edit"
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
            v-bind="attrs"
            filled
            :input-value="selected"
            @click="select"
            color="#FFE0B2"
            text-color="black"
        >
          <strong>{{ item }}</strong>
        </v-chip>
      </template>
    </v-autocomplete>
  </div>

</template>

<script>
export default {
  name: "Diet",
  props:{
    edit:null
  },
  data() {
    return {
      selected: '',
      items: this.$store.state.diet,
    };
  },
  created() {
    this.selected = this.$store.state.select_diet.diet
  },
  watch: {
    selected() {
      this.$store.commit('changeDietMut', this.selected)
    },
  },
  methods: {
    remove() {
      this.selected = ''
    },
  }
}

</script>

<style scoped>

</style>