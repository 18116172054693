import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Search from '../views/Search.vue'
import Favourites from '../views/Favourites.vue'
import Settings from '../views/Settings.vue'
import Recipe from '../views/Recipe.vue'
import loading from "../views/Loading";

Vue.use(VueRouter)

const TITLE = "Cookie"
const SPLIT = ' - '

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: "Home" + SPLIT + TITLE
        }
    },
    {
        path: '/search',
        name: 'Search',
        component: Search,
        meta: {
            title: "Search" + SPLIT + TITLE
        }
    },
    {
        path: '/favour',
        name: 'Favourites',
        component: Favourites,
        meta: {
            title: "Favourites" + SPLIT + TITLE
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            title: "Settings" + SPLIT + TITLE
        }
    },
    {
        path: '/recipe/:id',
        name: 'Recipe',
        component: Recipe,
        meta: {}
    },
    {
        path: "*",
        component: loading,
        meta: {
            title: "You're being cooked :)"
        }
    },
]


const router = new VueRouter({
    //mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

export default router
