<template>
  <v-hover v-slot="{ hover }" :open-delay="50">
    <v-card
        transition="scale-transition"
        :class="{ 'on-hover': hover }"
        :elevation="hover ? 12 : 2"
        :to="route ? `/recipe/${id}` : ''"
        min-height="200"
        color="orange lighten-5"
    >
      <v-img
          :src=image
          max-height="200"
      >
        <slot></slot>
      </v-img>
      <div class="icon-slot">
        <v-icon color="primary" class="icon-position">
          mdi-primary
        </v-icon>
        <slot></slot>
      </div>
      <v-card-title class="my-n3 mb-n6">
        <div class="headerClass">
          {{ title }}
        </div>
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            icon
            small
            style="margin-right: 10px;"
            color="deep-orange darken-1"
            @click.prevent.stop="favourite()"
        >

          <v-icon v-if="!isLiked">mdi-heart-outline</v-icon>
          <v-icon v-else>mdi-heart</v-icon>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-hover>
</template>

<script>

export default {
  components: {},
  name: "RecipeCard",
  props: {
    title: String,
    image: String,
    id: Number,
    route: {
      default: true,
    },
  },
  methods: {
    favourite() {
      const thisRecipe = '{"id":' + this.id +
          ',"title":"' + this.title +
          '","image":"' + this.image + '"}'
      console.log(thisRecipe)
      const Obj = JSON.parse(thisRecipe)
      console.log(Obj)

      if (!this.isLiked) {
        this.$store.commit('addFav', Obj)
        this.isLiked = true;
      } else {
        this.$store.commit('delFav', Obj.id)
        this.isLiked = false
      }
      console.log(this.$store.state.favourites)
      //setTimeout(() =>(location.reload()),2000)
    }
  },
  created() {
    for (const key in this.$store.state.favourites.favourites) {
      if (this.$store.state.favourites.favourites[key].id === this.id) {
        this.isLiked = true
      }
    }
  },
  data() {
    return {
      isLiked: false,
    }

  }
};
</script>

<style>

.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-slot {
  position: relative;
}

.icon-slot > div {
  top: 0;
  position: absolute;
  z-index: 1;
}

.icon-position {
  opacity: 0.8;
  display: flex !important;
  position: relative;
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>