<template>
  <v-card class="mt-2" color="transparent" elevation="5">
    <v-card-title class="py-2">
      Ingredients
    </v-card-title>
    <v-divider class="mx-2"></v-divider>
    <v-list dense color="transparent" elevation="5" class="mt-0 pt-0">
      <v-list-item dense v-for="(item,index) in value" :key="index">
          <v-list-item-title class="pl-2 text-subtitle-1 flex row">
            <v-checkbox color="primary"></v-checkbox>
            <v-list-item-content>
              {{ item.measures.metric.amount + ' ' + item.measures.metric.unitShort }} {{ item.name.charAt(0).toUpperCase() + item.name.slice(1) }}
            </v-list-item-content>
          </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>

</template>

<script>

export default {
  components: {},
  props: {
    value: {},
  },
  data() {
    return {
      'a': 1
    };
  },
  mounted() {

  },
  watch: {},
  methods: {},
};
</script>

<style>

</style>
