<template>
  <div>
    <h2 class="mb-4">Instructions</h2>
    <v-card
        elevation="5"
        @click="toggleDisabled(index)"
        class="mt-2" color="transparent"
        v-for="(item,index) in value"
        :key="index">
      <v-card-title>
        Step {{ item.number }}
        <v-fade-transition>
          <v-icon v-show="isChecked(index)" size="24" class="ml-auto" color="brown">
            mdi-check
          </v-icon>
        </v-fade-transition>
      </v-card-title>
      <v-expand-transition>
        <div class="m-0 p-0" v-show="!isChecked(index)">
          <v-card-text class="text-body-1">
            {{ item.step }}
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      disabledSteps: [],
    };
  },

  mounted() {
  },

  watch: {
    value: {
      handler() {
        this.disabledSteps = [];
      },
    },
  },

  methods: {
    isChecked(stepIndex) {
      if (this.disabledSteps.includes(stepIndex)) {
        return "disabled-card";
      } else {
        return;
      }
    },
    toggleDisabled(stepIndex) {
      if (this.disabledSteps.includes(stepIndex)) {
        let index = this.disabledSteps.indexOf(stepIndex);
        if (index !== -1) {
          this.disabledSteps.splice(index, 1);
        }
      } else {
        this.disabledSteps.push(stepIndex);
      }
    },

  },
};
</script>


