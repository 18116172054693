export function shuffle(arr) {
    arr.sort(() => Math.random() - 0.5);
}

export function sortAToZ(arr) {
    arr.sort((a, b) => {
        const textA = a.title.toUpperCase();
        const textB = b.title.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
}
