<template>
  <div>
    <v-card color="transparent" elevation="5" class="mt-2">
      <v-card-title class="py-2">
        Nutrition
      </v-card-title>
      <v-divider class="mx-2"></v-divider>
      <v-list color="transparent" elevation="5" dense class="mt-0 pt-0">
        <v-list-item v-for="(item,index) in value" :key="index">
          <v-list-item-content>
            <v-list-item-title class="pl-3 text-subtitle-1 flex row">
              {{ item.name }}
              <v-spacer></v-spacer>
              {{ item.amount }} {{ item.unit }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },
  computed: {
  },
  created(){
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
