<template>
  <v-container fill-height justify-center>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
            :src="require('../assets/cookie.png')"
            class="my-3"
            contain
            height="200"
            aspect-ratio="1"
        />
      </v-col>

      <v-col class="mb-4" cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Cookie
        </h1>

        <p class="subheading font-weight-regular">
          Find out what to cook today!
        </p>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.smAndUp" class="mb-5" cols="12">
        <v-btn
            v-for="(button, i) in buttons"
            :key="i"
            class="mx-2"
            color="#FFE0B2"
            text-color="black"
            @click="$router.push(button.url)"
        >
          <v-icon left>{{ button.icon }}</v-icon>
          {{ button.text }}
        </v-btn>
      </v-col>

      <v-col v-for="(button, i) in buttons"
             :key="i"
             class="mb-5" cols="12"
      >
        <v-btn
            v-if="$vuetify.breakpoint.xsOnly"
            color="#FFE0B2"
            text-color="black"
            @click="$router.push(button.url)"
        >
          <v-icon left>{{ button.icon }}</v-icon>
          {{ button.text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      buttons: [
        {
          text: "Search",
          url: "/search",
          icon: "mdi-magnify"
        },
        {
          text: "Favourites",
          url: '/favour',
          icon: "mdi-heart-outline"
        },
        {
          text: "Settings",
          url: "/settings",
          icon: "mdi-wrench-outline"
        }
      ]
    }
  },
  created() {
  },
  methods: {},
  components: {},
}
</script>
