<template>
  <div>
    <v-card-title class="headline">
      {{ recipe.title }}
    </v-card-title>
    <v-card-text>
      <v-row style="margin: auto;">
        <v-btn
            small
            elevation="1"
            color="#FFE0B2"
            text-color="black"
            class="rounded static"
            style="margin-right: 10px;"
        >
          {{ recipe.readyInMinutes }} Minutes
        </v-btn>
        <v-btn
            small
            elevation="1"
            color="#FFE0B2"
            text-color="black"
            class="rounded static"
        >
          {{ recipe.servings }} serving(s)
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            icon
            small
            style="margin-right: 10px;"
            @click="favourite()"
        >

          <v-icon v-if="!isLiked">mdi-heart-outline</v-icon>
          <v-icon v-else>mdi-heart</v-icon>
        </v-btn>
        <v-btn
            icon
            small
            @click="share"
        >
          <v-icon v-if="!isShare">mdi-share</v-icon>
          <v-icon v-else>mdi-check</v-icon>
        </v-btn>

      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="5" lg="4">
          <Ingredients :value="recipe.extendedIngredients"/>
          <Nutrition :value="recipe.nutrition.nutrients"/>
        </v-col>

        <v-col cols="12" sm="12" md="7" lg="8">
          <Instructions :value="recipe.analyzedInstructions[0].steps"/>
        </v-col>
      </v-row>

      <v-row class="mt-2 mb-1">
        <v-col></v-col>
        <v-btn
            :href="recipe.spoonacularSourceUrl"
            color="#FFE0B2"
            text-color="black"
            target="_blank"
            class="rounded mr-4"
        >
          Spoonacular
          <v-icon right>mdi-open-in-new</v-icon>
        </v-btn>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import Nutrition from "@/components/Recipe/Nutrition.vue";
import Ingredients from "@/components/Recipe/Ingredients.vue";
import Instructions from "@/components/Recipe/Instructions.vue";

export default {
  components: {
    Ingredients,
    Nutrition,
    Instructions,
  },
  props: {
    recipe: {},
  },
  data() {
    return {
      disabledSteps: [],
      isShare: false,
      isLiked: false
    }
  },
  created() {
    for (const key in this.$store.state.favourites.favourites) {
      if (this.$store.state.favourites.favourites[key].id == this.recipe.id) {
        this.isLiked = true
      }
    }
  },
  watch: {
    isShare() {
      setTimeout(() => (this.isShare = false), 2000)
    },
    recipe() {
      for (const key in this.$store.state.favourites.favourites) {
        if (this.$store.state.favourites.favourites[key].id == this.recipe.id) {
          this.isLiked = true
        }
      }
    }
  },
  methods: {
    share() {
      navigator.clipboard.writeText(document.title + ' - ' + window.location.href).then(() => {
        console.log('copied')
        this.isShare = true
      })
          .catch(() => {
            console.log('failed')
          })

    },
    favourite() {
      const thisRecipe = '{"id":' + this.recipe.id +
          ',"title":"' + this.recipe.title +
          '","image":"' + this.recipe.image + '"}'
      console.log(thisRecipe)
      const Obj = JSON.parse(thisRecipe)

      if (!this.isLiked) {
        this.$store.commit('addFav', Obj)
        this.isLiked = true;
      } else {
        this.$store.commit('delFav', Obj.id)
        this.isLiked = false
      }
    }
  },
}
</script>

<style>
.static {
  pointer-events: none;
}
</style>
